import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Getting Started",
  "sort": 4,
  "title": "Rate limiting",
  "subtitle": "Learn more about SSO rate limiting."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Criipto Verify applies rate limits on two different levels:`}</p>
    <ol>
      <li parentName="ol">{`On a service-wide level, DoS and DDoS protection mechanisms are enforced`}</li>
      <li parentName="ol">{`On a per-user level, limits to the number of allowed single sign-on requests are enforced`}</li>
    </ol>
    <p>{`Note that we do not share the details of the DoS and DDoS protection, but below is more about the limits to single sign-on requests`}</p>
    <h2>{`Single sign-on (SSO) limits`}</h2>
    <p>{`Criipto Verify has two per-user rate limits for how many SSO attempts can be performed.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`There are no limits to the number of interactive user logins, that is, an actual login attempt where a user supplies hers or his credentials. The limits only apply to subsequent SSO requests based on the session established during interactive login.`}</p>
    </Highlight>
    <ul>
      <li parentName="ul">{`High-frequency guard: Maximum 2 SSO's per 5 seconds.`}</li>
      <li parentName="ul">{`Low-frequency guard: Maximum 6 SSO's per 20 minutes.`}</li>
    </ul>
    <p>{`The high-frequency guard protects against sudden spikes in traffic, while the low-frequency guard protects against long-running repeated SSO attempts.`}</p>
    <p>{`The settings for the high-frequency limit is chosen so it is possible to have a "mash-up" of websites where you must establish sessions in rapid succession after the interactive login, but also ensures that any given user cannot abuse the service.`}</p>
    <p>{`The settings for the low-frequency guard is chosen to ensure that, say, broken infrastructure in client deployments do not accidentally trigger an excessive load on the service. Such errors can trigger a very large load on the service when the simultaneous number of users becomes very large.`}</p>
    <p>{`Should a user exceed any of the limits, the service will respond with a `}<inlineCode parentName="p">{`429`}</inlineCode>{` HTTP status code (aka `}<inlineCode parentName="p">{`Too Many Requests`}</inlineCode>{`). The user's session will not be terminated if a limit is hit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      